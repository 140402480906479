import { injectIntl } from "gatsby-plugin-intl";
import React from "react";
import { Container } from "react-bootstrap";
// import wirewheel from "../images/wirewheel.svg";

const Footer = () => (
  <footer className="footer mt-auto py-3 bg-light text-center">
    <Container>
      <p className="my-auto">© {new Date().getFullYear()} AFLAC INCORPORATED</p>
    </Container>
  </footer>
);

export default injectIntl(Footer);
