import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
const _ = require("lodash");
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import { Card, CardDeck, Form, FormGroup } from "react-bootstrap";

const LanguageTypes = props => {
  const { languageTypes } = props;
  const itemEls = useRef(new Array());

  const handleChange = e => {
    if (e.target?.value) {
      e.stopPropagation();
    }
    const inputValue = e.target?.value || e;

    languageTypes.forEach(lang => {
      lang.isChecked = false;
    });

    let currentLanguageTypes = [...languageTypes];
    let targetIndex = currentLanguageTypes.findIndex(
      x => x.value === inputValue
    );

    currentLanguageTypes[targetIndex].isChecked = !currentLanguageTypes[
      targetIndex
    ].isChecked;

    itemEls.current[targetIndex].checked =
      currentLanguageTypes[targetIndex].isChecked;
    props.onLanguageTypesChange(currentLanguageTypes);
  };

  useEffect(() => {
    if (languageTypes.length === 1) {
      const inputValue = "English";

      languageTypes.forEach(lang => {
        lang.isChecked = false;
      });

      let currentLanguageTypes = [...languageTypes];

      let targetIndex = currentLanguageTypes.findIndex(
        x => x.value === inputValue
      );

      currentLanguageTypes[targetIndex].isChecked = !currentLanguageTypes[
        targetIndex
      ].isChecked;

      props.onLanguageTypesChange(currentLanguageTypes);
    }
  }, [languageTypes]);

  if (languageTypes.length > 1) {
    return (
      <Form noValidate>
        <FormGroup
          as={CardDeck}
          controlId="formBasicCheckbox"
          className="cards flex-row d-flex align-items-stretch flex-wrap"
        >
          {languageTypes.map((languageType, index) => {
            return (
              <Card
                style={{ cursor: "pointer" }}
                key={`${languageType.value}`}
                onClick={() => {
                  handleChange(languageType.value);
                }}
                className={itemEls.current[index]?.checked ? "active" : ""}
              >
                <Card.Body>
                  <Form.Check>
                    <Form.Check.Input
                      type="radio"
                      defaultChecked={itemEls.current[index]?.checked || false}
                      value={`${languageType.value}`}
                      id={`${languageType.value}`}
                      ref={element => (itemEls.current[index] = element)}
                      name="langugeType"
                      onClick={handleChange}
                    />
                    <Form.Check.Label className="h5">
                      <FormattedMessage
                        id={`request-types.${_.kebabCase(
                          languageType.value
                        )}.title`}
                      />
                    </Form.Check.Label>
                  </Form.Check>
                </Card.Body>
              </Card>
            );
          })}
        </FormGroup>
      </Form>
    );
  } else {
    return "";
  }
};

LanguageTypes.propTypes = {
  languageTypes: PropTypes.arrayOf(PropTypes.object),
  onLanguageTypesChange: PropTypes.func
};

export default injectIntl(LanguageTypes);
