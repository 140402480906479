import React, { useEffect, useState } from "react";
// import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import { Row, Col, Spinner, Form, Button } from "react-bootstrap";
import Layout from "../layouts/main";
import { useIntl } from "gatsby-plugin-intl";
import RequestTypesHeader from "../components/requestTypesHeader";
import IntakeFormWithToggles from "../components/intakeFormWithToggles";
import { getService } from "../components/getService";
import LanguageTypes from "../components/languageTypes";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { constants } from "../components/constants";

const IndexPage = () => {
  const [loader, setLoader] = useState(false);
  const [configValues, setConfigValues] = useState({});
  const [requestTypes, setRequestTypes] = useState([]);
  const [languageSelected, setLanguageSelected] = useState("");
  const [languageChecked, setLanguageChecked] = useState(false);
  const [languageOptionsCust, setLanguageOptionsCust] = useState([
    "English",
    "Spanish"
  ]);
  const [languageOptionsRestAll, setLanguageOptionsRestAll] = useState([
    "English"
  ]);
  const [stateSelected, setStateSelected] = useState("");
  const [nonCAStatesSelected, setNonCAStatesSelected] = useState(false);
  const [CAStateSelected, setCAStateSelected] = useState(false);
  const [relationshipValue, setRelationshipValue] = useState("");
  const [selectedRequestTypes, setSelectedRequestTypes] = useState([]);
  const [hasCorrectionRequestType, setHasCorrectionRequestType] = useState(
    false
  );
  const [hasAccDelCorrRequestType, setHasAccDelCorrRequestType] = useState(
    false
  );
  const intl = useIntl();
  const formType = "";
  let requestTypesHeaderItem = "";

  useEffect(() => {
    if (relationshipValue) {
      let selectedLang = "";
      let url = "";

      if (
        typeof languageOptionsCust[0] === "object" &&
        relationshipValue ===
          "Customer (Policyholder, Certificate Holder, Spouse or Dependent)"
      ) {
        selectedLang = languageOptionsCust.filter(
          lang => lang.isChecked === true
        );

        if (selectedLang.length > 0) {
          setLoader(true);
          if (selectedLang[0].value === "English") {
            setLanguageSelected("en");
            url = `https://wirewheel-p3-public-storage-aflac.s3.us-east-1.amazonaws.com/63a9b9e7b32e18001738ba85/p3-data.json`;
          } else {
            setLanguageSelected("es");
            url = `https://wirewheel-p3-public-storage-aflac.s3.us-east-1.amazonaws.com/63a9c3945496e0001d777cc8/p3-data.json`;
          }
        }
      }

      if (
        relationshipValue !==
        "Customer (Policyholder, Certificate Holder, Spouse or Dependent)"
      ) {
        setLoader(true);
        setLanguageSelected("en");
        switch (relationshipValue) {
          case "Employee":
            url = `https://wirewheel-p3-public-storage-aflac.s3.us-east-1.amazonaws.com/63a9c447b32e18001738bcf3/p3-data.json`;
            break;
          case "Job Applicant":
            url = `https://wirewheel-p3-public-storage-aflac.s3.us-east-1.amazonaws.com/63a9c67db32e18001738bdfd/p3-data.json`;
            break;
          case "Sales Agent or Broker":
            url = `https://wirewheel-p3-public-storage-aflac.s3.us-east-1.amazonaws.com/63ae6df15496e0001d777f80/p3-data.json`;
            break;
          case "Contractor":
            url = `https://wirewheel-p3-public-storage-aflac.s3.us-east-1.amazonaws.com/63ae6f175496e0001d778073/p3-data.json`;
            break;
          default:
            break;
        }
      }

      if (url) {
        let configOptions = {
          method: "GET",
          url
        };

        const configData = getService(configOptions);
        configData.then(response => {
          if (response.status === 200 && response.data) {
            setLoader(false);
            const { requests, settingsId, _id } = response.data.srrConfig;
            const actions = requests.reduce((actionsMap, request) => {
              actionsMap[request.requestType] = request;
              return actionsMap;
            }, {});
            setConfigValues({
              requests,
              actions,
              settings: settingsId,
              _id: _id
            });
          }
        });
      }
    }
  }, [languageOptionsCust, relationshipValue]);

  useEffect(() => {
    if (Object.keys(configValues).length > 1) {
      const { actions } = configValues;
      const activeRequestTypes = Object.keys(actions);
      // const popElemenet = activeRequestTypes.pop();
      // activeRequestTypes.unshift(popElemenet);
      setRequestTypes(activeRequestTypes);
      const multipleActiveForms = activeRequestTypes.length > 1;
      if (multipleActiveForms) {
        requestTypesHeaderItem = <RequestTypesHeader />;
      }
    }
  }, [configValues]);

  useEffect(() => {
    function initCheckedRequestTypes(languageTypes) {
      let reformattedArray = languageTypes.map(function(obj, index) {
        let rObj = {};
        rObj["id"] = index;
        rObj["value"] = obj;
        rObj["isChecked"] = false;
        return rObj;
      });
      return reformattedArray;
    }
    if (languageOptionsCust.length > 1) {
      const formattedArray = initCheckedRequestTypes(languageOptionsCust);
      setLanguageOptionsCust(formattedArray);
    }

    if (languageOptionsRestAll.length === 1) {
      const formattedArray = initCheckedRequestTypes(languageOptionsRestAll);
      setLanguageOptionsRestAll(formattedArray);
    }
  }, []);

  // handling the loader activation here
  const handleLoader = value => {
    setLoader(value);
  };

  const noLanguageTypesChecked = languages => {
    let noneChecked = languages.every(function(a) {
      return a.isChecked === false;
    });
    return noneChecked;
  };

  const handleLanguageTypesChange = languages => {
    let noneChecked = noLanguageTypesChecked(languages);
    setLanguageOptionsCust(languages);
    if (noneChecked) {
      setLanguageChecked(false);
    } else {
      setLanguageChecked(true);
    }
  };

  const handleSelectedState = e => {
    setStateSelected(e.target.value);
    if (e.target.value === "") {
      setNonCAStatesSelected(false);
      setCAStateSelected(false);
    }
  };

  const handleStateSubmit = e => {
    e.preventDefault();
    if (stateSelected === "California") {
      setNonCAStatesSelected(false);
      setCAStateSelected(true);
    } else {
      setNonCAStatesSelected(true);
      setCAStateSelected(false);
    }
  };

  const handleRelationship = e => {
    setRelationshipValue(e.target.value);
  };

  const handleSelectedRequestType = value => {
    setHasCorrectionRequestType(value.includes("correction"));
    setHasAccDelCorrRequestType(
      value.includes("access") ||
        value.includes("deletion") ||
        value.includes("correction")
    );
    setSelectedRequestTypes(value);
  };

  return (
    <>
      <SEO
        lang="en"
        title={intl.formatMessage({ id: "seo.title" })}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <Header />
      <Layout
        top={
          <>
            <Row>
              <Col xs={12}>
                <Form className="mt-3 mb-5" onSubmit={handleStateSubmit}>
                  <Form.Group>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Label
                          as="legend"
                          className="radio-label font-weight-bold"
                        >
                          <strong>State of Residence</strong>{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="state"
                          className="ml-4"
                          onChange={handleSelectedState}
                          disabled={nonCAStatesSelected || CAStateSelected}
                        >
                          <option value="">Select State</option>
                          {constants.statesList.map((obj, index) => (
                            <option key={index} value={obj.name}>
                              {obj.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                  {nonCAStatesSelected || CAStateSelected ? (
                    " "
                  ) : (
                    <Form.Group>
                      <Row className="mt-3">
                        <Col sm={12} md={10} lg={6} className="ml-4">
                          <Button
                            type="submit"
                            className="proceed-btn"
                            disabled={!stateSelected}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                </Form>
                {nonCAStatesSelected ? (
                  <section className="text-justify">
                    <p>
                      Thank you for your request. The privacy rights that are
                      available through this process are not available in your
                      state of residence.
                    </p>

                    <p>
                      As a financial institution and insurance company, personal
                      information that we may collect, process, store or
                      disclose is subject to various federal and state privacy
                      and data protections laws, including the
                      Gramm-Leach-Bliley Act (GLBA) and the Health Insurance
                      Portability and Accountability Act (HIPAA).
                    </p>
                    <p>
                      At Aflac, we value your privacy and consider the
                      protection of your personal information critically
                      important. If you would like to learn more about how Aflac
                      may collect, use and share your personal information,
                      please see the{" "}
                      <a
                        href="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        target="_blank"
                        rel="noreferrer noopener"
                        title="https://www.aflac.com/about-aflac/privacy-policy.aspx"
                      >
                        Aflac Privacy Policy
                      </a>{" "}
                      for more information.
                    </p>
                  </section>
                ) : (
                  ""
                )}

                {CAStateSelected ? (
                  <>
                    <Form>
                      <Form.Group className="mt-4">
                        <Row className="w-75">
                          <Col>
                            <Form.Label
                              as="legend"
                              className="radio-label font-weight-bold"
                            >
                              What is your current or former relationship to
                              Aflac? <span className="text-danger">*</span>
                            </Form.Label>
                            <Row className="ml-2">
                              <Col>
                                <Form.Check
                                  type="radio"
                                  inline
                                  label="Customer (Policyholder, Certificate Holder, Spouse or Dependent)"
                                  name="relationshipToAflac"
                                  id="customer"
                                  value="Customer (Policyholder, Certificate Holder, Spouse or Dependent)"
                                  className="left radio-label-item white-space-nowrap"
                                  onClick={handleRelationship}
                                />
                              </Col>
                            </Row>
                            <Row className="ml-2">
                              {" "}
                              <Col>
                                <Form.Check
                                  type="radio"
                                  inline
                                  label="Employee"
                                  name="relationshipToAflac"
                                  value="Employee"
                                  className="left radio-label-item white-space-nowrap"
                                  id="employee"
                                  onClick={handleRelationship}
                                />
                              </Col>
                            </Row>
                            <Row className="ml-2">
                              {" "}
                              <Col>
                                <Form.Check
                                  type="radio"
                                  inline
                                  label="Job Applicant"
                                  name="relationshipToAflac"
                                  value="Job Applicant"
                                  className="left radio-label-item white-space-nowrap"
                                  id="jobApplicant"
                                  onClick={handleRelationship}
                                />
                              </Col>
                            </Row>
                            <Row className="ml-2">
                              <Col>
                                <Form.Check
                                  type="radio"
                                  inline
                                  label="Sales Agent or Broker"
                                  name="relationshipToAflac"
                                  value="Sales Agent or Broker"
                                  className="left radio-label-item white-space-nowrap"
                                  id="salesAgentBroker"
                                  onClick={handleRelationship}
                                />
                              </Col>
                            </Row>
                            <Row className="ml-2">
                              <Col>
                                <Form.Check
                                  type="radio"
                                  inline
                                  label="Contractor"
                                  name="relationshipToAflac"
                                  value="Contractor"
                                  className="left radio-label-item white-space-nowrap"
                                  id="contractor"
                                  onClick={handleRelationship}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                {relationshipValue !== "" ? (
                  <>
                    <h1 className="header--title mb-3">
                      <FormattedMessage
                        id="header.title"
                        defaultMessage="Individual Rights Request"
                      />
                    </h1>
                    {relationshipValue ===
                    "Customer (Policyholder, Certificate Holder, Spouse or Dependent)" ? (
                      <>
                        <Row>
                          <Col className="mb-4">
                            <FormattedHTMLMessage
                              id="header.langDescription"
                              values={{ item: msg => ({ msg }) }}
                            />
                          </Col>
                        </Row>
                        <LanguageTypes
                          languageTypes={languageOptionsCust}
                          onLanguageTypesChange={handleLanguageTypesChange}
                        />
                      </>
                    ) : (
                      <LanguageTypes
                        languageTypes={languageOptionsRestAll}
                        onLanguageTypesChange={handleLanguageTypesChange}
                      />
                    )}
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </>
        }
        center={""}
      />
      {languageChecked ? (
        <Layout
          top={""}
          center={
            <>
              {loader ? (
                <div className="">
                  <Spinner
                    animation="grow"
                    variant="primary"
                    className="spinner"
                  />
                </div>
              ) : Object.keys(configValues).length > 1 &&
                requestTypes.length > 1 &&
                languageSelected ? (
                <>
                  <Row>
                    <Col className="my-3">
                      <FormattedHTMLMessage
                        id="header.description"
                        values={{ item: msg => ({ msg }) }}
                      />
                      {loader ? "" : requestTypesHeaderItem}
                    </Col>
                  </Row>
                  <IntakeFormWithToggles
                    activeRequestTypes={requestTypes}
                    requestId={configValues.settings}
                    formType={formType}
                    requestType={selectedRequestTypes}
                    settingsId={configValues.settings}
                    formId={configValues._id}
                    formFields={
                      hasCorrectionRequestType
                        ? configValues.requests[2].fields
                        : hasAccDelCorrRequestType
                        ? configValues.requests[0].fields
                        : configValues.requests[3].fields
                    }
                    multipleActiveForms={
                      Object.keys(configValues.actions).length > 1
                    }
                    enableLoader={handleLoader}
                    configValues={configValues}
                    selectedlang={languageSelected}
                    relationshipValue={relationshipValue}
                    requestTypeSelected={handleSelectedRequestType}
                    hasAccDelCorrRequestType={hasAccDelCorrRequestType}
                    hasCorrectionRequestType={hasCorrectionRequestType}
                    stateSelected={stateSelected}
                  />
                </>
              ) : (
                " "
              )}
            </>
          }
        />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
};

export default IndexPage;
