import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import IntakeForm from "../components/intakeForm";
import RequestTypes from "../components/requestTypes";

class IntakeFormWithToggles extends React.Component {
  constructor(props) {
    super(props);
    this.handleRequestTypesChange = this.handleRequestTypesChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.setFormData = this.setFormData.bind(this);
    this.state = {
      requestTypes: this.initCheckedRequestTypes(this.props.activeRequestTypes),
      showForm: this.props.multipleActiveForms ? "hide" : "view",
      showToggles: this.props.activeRequestTypes.length > 1 ? "view" : "hide",
      disableSubmit: true,
      formData: {}
    };
  }

  initCheckedRequestTypes(activeRequestTypes) {
    let reformattedArray = activeRequestTypes.map(function(obj, index) {
      let rObj = {};
      rObj["id"] = index;
      rObj["value"] = obj;
      rObj["isChecked"] = false;
      return rObj;
    });
    return reformattedArray;
  }

  noRequestTypesChecked(requestTypes) {
    let noneChecked = requestTypes.every(function(a) {
      return a.isChecked === false;
    });
    return noneChecked;
  }

  handleRequestTypesChange(requestTypes) {
    function filterByChecked(item) {
      if (requestTypes.length > 1) {
        if (item.isChecked) {
          return item["value"];
        }
      } else {
        if (!item.isChecked) {
          return item["value"];
        }
      }
    }
    let arrayByIsChecked = requestTypes.filter(filterByChecked);
    let providedRequests = arrayByIsChecked.map(item => {
      return item.value;
    });
    this.props.requestTypeSelected(providedRequests);
    let noneChecked = this.noRequestTypesChecked(requestTypes);
    this.setState({
      requestTypes: requestTypes,
      showForm: noneChecked ? "hide" : "view"
    });
  }

  handleFormChange(val) {
    this.setState({
      disableSubmit: val
    });
  }

  setFormData(formData) {
    this.setState(formData);
  }

  renderRequestTypesToggles() {
    if (this.state.showToggles === "hide") {
      return <div></div>;
    } else {
      return (
        <Row>
          <Col>
            <RequestTypes
              formType={this.props.formType}
              requestTypes={this.state.requestTypes}
              onRequestTypesChange={this.handleRequestTypesChange}
            />
          </Col>
        </Row>
      );
    }
  }

  renderIntakeForm(requestType) {
    if (this.state.showForm === "hide") {
      return <div></div>;
    } else {
      return (
        <Row className="justify-content-md-center">
          <Col xs lg="8">
            <IntakeForm
              settingsId={this.props.settingsId}
              formId={this.props.formId}
              requestId={this.props.requestId}
              formType={this.props.formType}
              requestType={requestType}
              requestTypes={this.state.requestTypes}
              formFields={this.props.formFields}
              disableSubmit={this.state.disableSubmit}
              onFormChange={this.handleFormChange}
              setFormData={this.setFormData}
              formData={this.state.formData}
              enableLoader={this.props.enableLoader}
              configValues={this.props.configValues}
              selectedlang={this.props.selectedlang}
              relationshipValue={this.props.relationshipValue}
              hasAccDelCorrRequestType={this.props.hasAccDelCorrRequestType}
              hasCorrectionRequestType={this.props.hasCorrectionRequestType}
              stateSelected={this.props.stateSelected}
            />
          </Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderRequestTypesToggles()}
        {this.renderIntakeForm(this.props.requestType)}
      </div>
    );
  }
}

IntakeFormWithToggles.propTypes = {
  activeRequestTypes: PropTypes.array,
  formType: PropTypes.string,
  requestId: PropTypes.string,
  settingsId: PropTypes.string,
  formId: PropTypes.string,
  formFields: PropTypes.arrayOf(PropTypes.object),
  requestType: PropTypes.array,
  multipleActiveForms: PropTypes.bool,
  enableLoader: PropTypes.func,
  configValues: PropTypes.any,
  selectedlang: PropTypes.string,
  relationshipValue: PropTypes.string,
  requestTypeSelected: PropTypes.any,
  hasAccDelCorrRequestType: PropTypes.bool,
  hasCorrectionRequestType: PropTypes.bool,
  stateSelected: PropTypes.string
};

export default IntakeFormWithToggles;
