import { injectIntl } from "gatsby-plugin-intl";
import React from "react";
import PropTypes from "prop-types";
import { Navbar, Container, Row, Col } from "react-bootstrap";
// import LanguageSwitcher from "./languageSwitcher";
import Logo from "../images/aflac-logo.png";

// TODO image data should come from API.
// TODO make this pull from page data.
// Probably somewhere else and imported in the `LanguageSwitcher` component
// const supportedLanguages = [
//   {
//     label: `English (default)`,
//     languageTag: `en`
//   }
// ];

const Header = () => (
  <Navbar variant="light" expand={true} className="main-nav">
    <Container>
      <Row noGutters={true} style={{ width: "100%" }}>
        <Col
          sm={12}
          md={8}
          className="justify-content-xs-center justify-content-md-start"
        >
          <Navbar.Brand className="align-self-center">
            <a href="https://www.aflac.com" target="_blank" rel="noreferrer">
              <img
                alt="Aflac Logo"
                src={Logo}
                width="400px"
                height="112px"
                className="d-inline-block align-top"
              />
            </a>
          </Navbar.Brand>
        </Col>
        {/* <Col sm={12} md={4}>
          <Nav className="justify-content-md-end">
            <Dropdown as={NavItem} className="language-switcher">
              <Dropdown.Toggle
                as={NavLink}
                variant="secondary"
                id="language-switcher-button"
                title="Choose language"
              >
                {supportedLanguages.find(item => item.languageTag === props.intl.locale).label}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-md-right dropdown-menu-sm-left">
                <LanguageSwitcher currentForm={props.currentForm} supportedLanguages={supportedLanguages} />
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Col> */}
      </Row>
    </Container>
  </Navbar>
);

Header.propTypes = {
  intl: PropTypes.object,
  currentForm: PropTypes.string
};

export default injectIntl(Header);
