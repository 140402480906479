import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
const _ = require("lodash");
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import { Card, CardDeck, Form, FormGroup } from "react-bootstrap";

const RequestTypes = props => {
  const [checkedRequestTypes, setCheckedRequestTypes] = useState(
    props.requestTypes
  );
  const itemEls = useRef(new Array());

  const handleChange = e => {
    if (e.target?.value) {
      e.stopPropagation();
    }
    const inputValue = e.target?.value || e;
    let currentRequestTypes = [...checkedRequestTypes];
    let targetIndex = currentRequestTypes.findIndex(
      x => x.value === inputValue
    );
    currentRequestTypes[targetIndex].isChecked = !currentRequestTypes[
      targetIndex
    ].isChecked;
    itemEls.current[targetIndex].checked =
      currentRequestTypes[targetIndex].isChecked;
    setCheckedRequestTypes(currentRequestTypes);
    props.onRequestTypesChange(currentRequestTypes);
  };

  let requestTypes = checkedRequestTypes;

  return (
    <Form noValidate>
      <FormGroup
        as={CardDeck}
        controlId="formBasicCheckbox"
        className="cards flex-row d-flex align-items-stretch flex-wrap"
      >
        {requestTypes.map((requestType, index) => {
          return (
            <Card
              style={{ cursor: "pointer" }}
              key={`${requestType.value}`}
              onClick={() => {
                handleChange(requestType.value);
              }}
              className={itemEls.current[index]?.checked ? "active" : ""}
            >
              <Card.Body>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    defaultChecked={itemEls.current[index]?.checked || false}
                    value={`${requestType.value}`}
                    id={`${requestType.value}`}
                    ref={element => (itemEls.current[index] = element)}
                    onClick={handleChange}
                  />
                  <Form.Check.Label className="h5">
                    <FormattedMessage
                      id={`request-types.${_.kebabCase(
                        requestType.value
                      )}.title`}
                    />
                  </Form.Check.Label>
                </Form.Check>
                <Card.Text>
                  <FormattedMessage
                    id={`request-types.${_.kebabCase(
                      requestType.value
                    )}.description`}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          );
        })}
      </FormGroup>
    </Form>
  );
};

RequestTypes.propTypes = {
  requestTypes: PropTypes.arrayOf(PropTypes.object),
  onRequestTypesChange: PropTypes.func
};

export default injectIntl(RequestTypes);
